import React, {Component} from "react";
import {graphql} from "gatsby";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Meta from "../components/Meta";
import BookingTable from "../components/BookingTable";
import '../components/layout.scss';
import { store } from '../app/store';
import { Provider } from 'react-redux';

class PageBooking extends Component {
    render() {

        let meta = {
            metaTitle: "Book a table – [name] ",
            metaDescription: "Make a table booking at our [displayLocation] pub restaurant",
            ogTitle: "Book a table – [name]",
            ogDescription: "Check table availability at our [displayLocation] restaurant ",
            twitterTitle: "Book a table – [name] ",
            twitterDescription: "Make a table reservation at our restaurant in [displayLocation]",
            ogImage: null,
            twitterImage: null,
        }

      return (
          <>
            <Provider store={store}>
              <CssBaseline />
              <style>{"body{max-width:100%;overflow-x:hidden;}img{max-width:100%;}.gatsby-image-wrapper{position:relative;}.gatsby-image-wrapper picture{position:absolute;top:0;right:0;bottom:0;left:0;}.mb-0{margin-bottom: 0 !important;"}</style>
              <Meta meta={meta} title={meta.title} ii={this.props.data.wp.themeOptions.ThemeOptions.individualInnsBranding} />
              <BookingTable themeOptions={this.props.data.wp.themeOptions} />
            </Provider>
          </>
      )
    }
}

export const query = graphql`
query {
  wp {
    themeOptions {
      accommodationActive
      roomBookingCTAText
      roomBookingImage
      roomBookingImageText
      roomBookingLink
      tableBookingAccountId
      tableBookingActive
      tableBookingBrandId
      tableBookingCTAText
      tableBookingImage
      tableBookingImageText
      tableBookingRestaurantId
      liveResSiteId
      ThemeOptions {
        siteLogo {
          mediaItemUrl
        }
        individualInnsBranding
      }
    }    
  }
}
`;

export default PageBooking
