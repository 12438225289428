import  React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from "@material-ui/core/Button/Button";
import arrowPrev from '../../images/arrow-prev-light.png';
import Link from '../Link';

const useStyles = makeStyles((theme) => ({
    bookingTable: {
        backgroundColor: theme.palette.secondary.main,
        padding: 22,
        position: 'relative',
        minHeight: '100vh',
        [theme.breakpoints.up('md')]: {
            padding: 60,
        },
        '& div:focus': {
            outline: 'none',
        },
        '& .site-logo': {
            display: 'inline-block',
            marginBottom: 24,
            [theme.breakpoints.up('md')]: {
                marginBottom: 60,
            },
            '& img': {
                maxHeight: 30,
                [theme.breakpoints.up('lg')]: {
                    maxHeight: 38,
                }
            },
        },
        '& .back-link': {
            color: theme.palette.secondary.contrastText,
            fontSize: 16,
            letterSpacing: '0.016em',
            lineHeight: 1.5,
            float: 'right',
            '& .MuiButton-label': {
                '& span': {
                    marginRight: 10,
                    width: 18,
                    height: 18,
                    backgroundImage: 'url(' + arrowPrev + ')',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    display: 'inline-block',
                    verticalAlign: 'text-top',
                }
            },
        },
        '& iframe': {
            height: 'calc(100vh - 120px) !important',
            maxWidth: 970,
            margin: '0 auto',
            display: 'block',
            [theme.breakpoints.up('md')]: {
                height: 'calc(100vh - 240px) !important',
            }
        }
    },
    bookingTableTheme: {
        ...theme.bookingSplitTheme,
    }
}));

export default function BookingTable(props) {

    const classes = useStyles();

    let params = null;
    if (props.themeOptions.liveResSiteId) {
        params = 'siteId=' + props.themeOptions.liveResSiteId;
    } else {
        params = 'rest=' + props.themeOptions.tableBookingRestaurantId;
    }
    
    return (
        <div className={`${classes.bookingTable} ${classes.bookingTableTheme}`}>
            <Button className="back-link" onClick={() => window.history.back()}><span></span> Back</Button>
            <div className="site-logo">
                <Link to="/">
                    <img src={props.themeOptions.ThemeOptions.siteLogo.mediaItemUrl} alt=""/>
                </Link>
            </div>
            <div>
                <iframe
                    title="bookATable"
                    style={{width: "100%", height: "450px", border: "none", }}
                    src={process.env.GATSBY_ADMIN_URL + `booking.php?${params}`}
                />
            </div>
        </div>
    )
};
